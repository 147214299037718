<template>
  <div class="notificationSwitzerland">
    <div
      class="card"
    >
      <Portlet
        title="Notification short-term work Switzerland"
        icon="bell"
      >
        <LoadingPlaceholder v-if="loading" />
        <template v-else>
          <div class="alert alert-danger">
            {{ $t('notificationSwitzerlandComp.title') }}
          </div>
          <div class="row">
            <form>
              <div class="mb-2 ml-2">
                <strong>{{ $t('notificationSwitzerlandComp.subtitle') }}</strong>
              </div>
              <div class="form-group ml-2">
                <label>{{ $t('customer') }}</label>
                <input
                  v-model="customer.customer"
                  readonly
                  class="form-control"
                  placeholder="Customer"
                >
              </div>
             
              <div class="form-group mt-2 ml-2">
                <label>
                  <strong>{{ $t('contactPerson') }}</strong>
                </label>
                <select
                  v-model="notification.contactPerson"
                  v-focus
                  class="form-control"
                  @change="contactPersonChanged()"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('pleaseSelectOne') }}
                  </option>
                  <option
                    v-for="(contact, index) in contacts"
                    :key="`contact-${ index }`"
                    :value="index"
                  >
                    {{ getValue(contact) }}
                  </option>
                </select>
              </div>
              <div class="form-group ml-2">
                <label>{{ $t('phone') }}</label>
                <input
                  v-model="notification.phoneNumber"
                  readonly
                  class="form-control"
                  placeholder="please select a contact person"
                >
              </div>
              <div class="form-group ml-2">
                <label>{{ $t('email') }}</label>
                <input
                  v-model="notification.email"
                  readonly
                  class="form-control"
                  placeholder="please select a contact person"
                >
              </div>
              <div class="ml-2">
                <label><strong>{{ $t('notificationSwitzerlandComp.place') }}</strong></label>
              </div>  
              <div class="form-group ml-2">
                <label>{{ $t('address') }}</label>               
                <input
                  v-model="customer.address"
                  class="form-control"
                  placeholder="address"
                >
              </div>   
              <div class="form-row ml-2">
                <div class="form-group col-md-6">
                  <label>{{ $t('zipCode') }}</label>
                  <input
                    v-model="customer.zipCode"
                    class="form-control"
                    placeholder="ZIP Code"
                  >
                </div>
                <div class="form-group col-md-6">
                  <label>{{ $t('city') }}</label>
                  <input
                    v-model="customer.city"
                    class="form-control"
                    placeholder="City"
                  >
                </div>
              </div>
              <div class="mb-2 ml-2 mt-2">
                <strong>{{ $t('timespan') }}</strong>
              </div>
              <div class="form-group ml-2">
                <label>{{ $t('from') }} - {{ $t('until') }}</label>
                <date-picker
                  v-model="notification.dates"
                  :editable="true"
                  :clearable="false"
                  range
                  confirm
                  range-separator="-"
                  type="date"
                  lang="en"
                  format="DD.MM.YYYY"
                  class="w-100"
                  value-type="date"
                  :first-day-of-week="1"
                  @input-error="catchInputError"
                />
              </div>
              <div class="form-group ml-2">
                <label><strong>{{ $t('notificationSwitzerlandComp.purpose') }}</strong></label>               
                <input
                  v-model="notification.purpose"
                  class="form-control"
                  placeholder="please describe"
                >
              </div>
              <div class="form-group ml-2">
                <label><strong>{{ $t('notificationSwitzerlandComp.technicianName') }}</strong></label>               
                <input
                  v-model="notification.technician"
                  class="form-control"
                  placeholder="please enter a name"
                >
              </div>
            </form>
          </div>
          <button
            :disabled="!notification.dates || notification.contactPerson == null || notification.purpose == null || loading"
            class="btn btn-sm btn-primary mt-3"
            @click="sendMail()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="envelope"
            />
            <span>{{ $t('send') }}</span>
          </button>
        </template>
      </Portlet>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "NotificationSwitzerland",
  components: {
    DatePicker
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      notification: {contactPerson: null},
      contacts: null,
      customer: {},
      loading: false
    }
  },
  async created () {
    await this.getContacts();
    await this.getCustomer();
  },
  methods: {
    catchInputError (error) {
      let index = error.indexOf('-');

      let firstDate = error.substring(0, index - 1);
      let secondDate = error.substring(index + 2, error.length);

      let firstDateParts = this.splitDate(firstDate);
      let secondDateParts = this.splitDate(secondDate);
      
      firstDate = new Date(firstDateParts[2], firstDateParts[1] - 1, firstDateParts[0], firstDateParts[3], firstDateParts[4]);
      secondDate = new Date(secondDateParts[2], secondDateParts[1] - 1, secondDateParts[0], secondDateParts[3], secondDateParts[4]);

      if (isNaN(firstDate.getTime())) {
        return;
      }
      if (isNaN(secondDate.getTime())) {
        return;
      }

      this.notification.dates = [firstDate, secondDate];
    },
    sendMail () {
      this.loading = true;
      let sendMailDto = Object.assign({}, this.notification);
      sendMailDto.contactPerson = this.getValue(this.contacts[this.notification.contactPerson]); //get name from index which is used in v-model
      sendMailDto.from = this.notification.dates[0];
      sendMailDto.until = this.notification.dates[1];
      sendMailDto.customer = this.customer.customer;
      sendMailDto.address = this.customer.address;
      sendMailDto.city = this.customer.city;
      sendMailDto.zipCode = this.customer.zipCode;
      sendMailDto.customerAddress = this.customer.customerAddress;
      sendMailDto.customerCity = this.customer.customerCity;
      sendMailDto.customerZipCode = this.customer.customerZipCode;
      delete sendMailDto.dates;
      this.axios.post(`/Notification/NotifyWorkSwitzerland`, sendMailDto)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.$snotify.success("Succesfully sent mail to HR department");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getContacts () {
      this.loading = true;
      await this.axios.get('/Contact/GetContacts/' + this.installationId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data;
        });

    },
    async getCustomer () {
      this.loading = true;
      await this.axios.get('/Installation/GetCustomer?installationId=' + this.installationId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.customer = response.data.result;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    contactPersonChanged () {
      let contact = this.contacts[this.notification.contactPerson];
      this.notification.email = contact.email;
      this.notification.phoneNumber = contact.phone;
    },
    getValue (contact) {
      if (contact.firstname && contact.surname)
      {
        return contact.firstname + ' ' + contact.surname;
      }
      else if (!contact.firstname && contact.surname)
      {
        return contact.surname;
      }
      else if (contact.firstname && !contact.surname)
      {
        return contact.firstname;
      }
    }
  }
}
</script>

<style>
.mx-calendar-content .cell.inrange {
    background-color: #abbfc7;
}
.mx-calendar-content .cell.disabled {
    background-color: #f3f3f3;
}
</style>